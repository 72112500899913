import React, { useState } from "react";
import { Card, Tabs, Tab, Typography, Spinner, Alert } from "@platform-ui/design-system";
import { Box } from "@mui/material";
import TaskHistory from "./components/TaskHistory";
import CustomFieldsMappingProvider from "./context/InboundCFMappingContext";
import CustomFieldsMappingCard from "./components/InboundCFMappingCard";
import { useFlipperHook } from "../../../Tables/helpers";

interface ShopifyProps {
  tabName?: string;
}

const Shopify: React.FC<ShopifyProps> = ({ tabName }) => {
  const isTaskHistoryTab = tabName === "taskHistory";
  const isSyncTab = tabName === "sync";
  const [syncSubTab, setSyncSubTab] = useState<number>(0);
  const [shopifyFeatureEnabled, shopifyFeatureLoading] = useFlipperHook('enable_shopify_connector');

  const handleSyncSubTabChange = (_, newIndex: number) => {
    setSyncSubTab(newIndex);
  };

  if (shopifyFeatureLoading) {
    return <Spinner />;
  }

  if (!shopifyFeatureEnabled) {
    return (
      <Alert
        open
        center
        variant='outlined'
        severity='warning'
        body={`Shopify connector is not enabled for your account.`}
      />
    );
  }

  return (
    <>
      {isTaskHistoryTab && (
        <Card id="taskHistory-table" body={<TaskHistory />} />
      )}

      {isSyncTab && (
        <Card id="sync-card"
          body={<Box sx={{ mt: 2 }}>
            <Tabs
              a11yLabel="sync-direction"
              value={syncSubTab}
              dsOnChange={handleSyncSubTabChange}
            >
              <Tab
                id="outbound-tab"
                aria-controls="outbound-integration"
                label="Outbound (Zuora to Shopify)"
              />
              <Tab
                id="inbound-tab"
                aria-controls="inbound-integration"
                label="Inbound (Shopify to Zuora)"
              />
            </Tabs>

            {syncSubTab === 0 && (
              <Box sx={{ p: 2 }}>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Configure how data from Zuora is synchronized to Shopify. This
                  includes mapping Zuora products, rate plans, rate plan charges,
                  and customer data to corresponding Shopify stores.
                </Typography>
                <CustomFieldsMappingProvider>
                  <CustomFieldsMappingCard id="inbound-custom-fields-mapping" />
                </CustomFieldsMappingProvider>
              </Box>
            )}

            {syncSubTab === 1 && (
              <Box sx={{ p: 2 }}>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Coming soon! This feature will allow you to configure how data
                  from Shopify is synchronized to Zuora.
                </Typography>
              </Box>
            )}
          </Box>}
        />
      )}
    </>
  );
};

export default Shopify;